<template>
    <header
        class="global-header"
        :class="{
            'sticky': state.isSticky,
            'no-clickable': hideMobileMenu
        }"
    >
        <slot
            v-if="props.isCmsShopBrandingZwEnabled"
            name="zumbaheaderlogo"
        />
        <a
            v-else
            :href="'/' + props.localizationUser"
            title="Zumba Fitness"
            class="gh-logo"
            data-ga-category="Global Logo"
            data-ga-action="Click"
            :data-ga-label="props.gaLabel"
            data-ga4-event-name="global_logo"
            data-ga4-action="Click"
            :data-ga4-details="props.gaLabel"
        >
            <img
                srcset="@icons/logo@2x.png 2x"
                src="@icons/logo.png"
                alt="Zumba Fitness"
                width="150"
                height="45"
            >
        </a>

        <div class="gh-menu-left">
            <MenuButton
                v-if="!hideMobileMenu"
                :navigation-text="props.t.navigationText"
            />
        </div>
        <div
            class="gh-menu-right"
            :class="{ 'hide-nav-ele': hideNavigationElements }"
        >
            <ul class="gh-secondary-nav">
                <li
                    v-if="props.mtvHeaderEnabled"
                    class="gh-secondary-nav-item"
                >
                    <Profile
                        :t="props.t"
                        :can-access-member-menu="props.canAccessMemberMenu"
                        :can-access-six-weeks-program-profile="props.canAccessSixWeeksProgramProfile"
                        :can-consumer-view-dashboard="props.canConsumerViewDashboard"
                        :can-gym-owner-view-dashboard="props.canGymOwnerViewDashboard"
                        :can-manage-gym-profile="props.canManageGymProfile"
                        :can-manage-linked-gym-instructors="props.canManageLinkedGymInstructors"
                        :current-user-locale="props.currentUserLocale"
                        :ga-label="props.gaLabel"
                        :gym-active-link="props.gymActiveLink"
                        :is-authenticated="props.isAuthenticated"
                        :is-trained-non-member="props.isTrainedNonMember"
                        :item-class="props.itemClass"
                        :link-class="props.linkClass"
                        :should-show-join-link="props.shouldShowJoinLink"
                        :user-has-delinquent-membership="props.userHasDelinquentMembership"
                        :user-has-benefit-member-portal="props.userHasBenefitMemberPortal"
                        :zin-responsive-link="props.zinResponsiveLink"
                        :login-url="props.loginUrl"
                        :sign-up-url="props.signUpUrl"
                        :is-pre-payment-access-enabled="props.isPrePaymentAccessEnabled"
                    />
                </li>
                <li v-if="props.mtvHeaderEnabled">
                    <slot name="countryflag" />
                </li>
            </ul>
        </div>
        <ul
            class="gh-main-nav"
            :class="{ 'hide-nav-ele': hideNavigationElements }"
        >
            <!-- 6 WEEK PROGRAM -->
            <li
                v-if="props.isSixWeekTopNavLinkEnabled &&
                    props.isSupportedSixWeekLocale &&
                    !props.isBaiUrl &&
                    !props.isTrainingUrl &&
                    !props.isZumbaAppMenuLaunchEnabled"
                class="gh-main-nav-item"
            >
                <a
                    v-if="props.userHasBenefitSixWeekProgram"
                    class="button is-green six-week-program__button"
                    :href="'/' + props.currentUserLocale + '/6week'"
                    :title="props.t.sixWeekProgramText"
                    data-ga-category="Navigation Menu"
                    data-ga-action="Six Week Program"
                    :data-ga-label="props.gaLabel"
                    data-ga4-event-name="navigation_menu"
                    data-ga4-action="Six Week Program"
                    :data-ga4-details="props.gaLabel"
                >
                    {{ props.t.sixWeekProgramText }}
                </a>
                <a
                    v-else
                    class="button is-green six-week-program__button"
                    :href="props.sixWeekSplashPageUrl"
                    target="_blank"
                    rel="noopener"
                    :title="props.t.splashPageMenuItemText"
                    data-ga-category="Navigation Menu"
                    data-ga-action="Six Week Program"
                    :data-ga-label="props.gaLabel"
                    data-ga4-event-name="navigation_menu"
                    data-ga4-action="Six Week Program"
                    :data-ga4-details="props.gaLabel"
                >
                    {{ props.t.splashPageMenuItemText }}
                </a>
            </li>

            <!-- LOCAL CLASSES -->
            <li
                v-if="props.isZumbaAppMenuLaunchEnabled"
                class="gh-main-nav-item"
            >
                <a
                    class="gh-main-nav-link"
                    :href="'/' + props.localizationUser +'/party'"
                    :title="props.t.localClassesTitleCaseText"
                    data-ga-category="Navigation Menu"
                    data-ga-action="Classes"
                    :data-ga-label="props.gaLabel"
                    data-ga4-event-name="navigation_menu"
                    data-ga4-action="Classes"
                    :data-ga4-details="props.gaLabel"
                >
                    {{ props.t.localClassesTitleCaseText }}
                </a>

                <ul
                    id="nav-classes"
                    class="text-left dropdown"
                >
                    <li>
                        <a
                            :href="'/' + props.currentUserLocale + '/class_search?searchType=in_person'"
                            data-ga-category="Navigation Menu"
                            data-ga-action="Classes_Find an In Person Class"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="navigation_menu"
                            data-ga4-action="Classes_Find an In Person Class"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.inPersonClassesTitleCaseText }}
                        </a>
                    </li>
                    <li>
                        <a
                            :href="'/' + props.currentUserLocale + '/class_search?searchType=virtual'"
                            data-ga-category="Navigation Menu"
                            data-ga-action="Classes_Find a Zoom Class"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="navigation_menu"
                            data-ga4-action="Classes_Find a Zoom Class"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.zoomClassesTitleCaseText }}
                        </a>
                    </li>
                    <li>
                        <a
                            :href="'/' + props.currentUserLocale + '/class_search?searchType=vod'"
                            data-ga-category="Navigation Menu"
                            data-ga-action="Classes_Find an VOD Class"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="navigation_menu"
                            data-ga4-action="Classes_Find a VOD Class"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.VODClassesTitleCaseText }}
                        </a>
                    </li>
                    <li>
                        <a
                            :href="'/' + props.currentUserLocale + '/pages/class'"
                            data-ga-category="Navigation Menu"
                            data-ga-action="Classes_Find a Class"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="navigation_menu"
                            data-ga4-action="Classes_Find a Class"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.classTypesText }}
                        </a>
                    </li>
                    <li>
                        <a
                            :href="'/' + props.currentUserLocale + '/event/nearMe'"
                            data-ga-category="Navigation Menu"
                            data-ga-action="Classes_Find an Event"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="navigation_menu"
                            data-ga4-action="Classes_Find an Event"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.eventsTitleCaseText }}
                        </a>
                    </li>
                    <li>
                        <a
                            :href="'/' + props.currentUserLocale + '/instructor'"
                            data-ga-category="Navigation Menu"
                            data-ga-action="Classes_Find an Instructor"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="navigation_menu"
                            data-ga4-action="Classes_Find an Instructor"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.findInstructorsText }}
                        </a>
                    </li>
                    <li v-if="props.isJobBoardFormEnabled">
                        <a
                            :href="'/' + props.currentUserLocale + '/clubs#job-form'"
                            data-ga-category="Navigation Menu"
                            data-ga-action="Classes_Hire an Instructor"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="navigation_menu"
                            data-ga4-action="Classes_Hire an Instructor"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.postAJobText }}
                        </a>
                    </li>
                </ul>
            </li>

            <!-- CLASSES -->
            <li
                v-else
                class="gh-main-nav-item"
            >
                <a
                    class="gh-main-nav-link"
                    :href="'/' + props.localizationUser +'/party'"
                    :title="props.t.classesTitleCaseText"
                    data-ga-category="Navigation Menu"
                    data-ga-action="Classes"
                    :data-ga-label="props.gaLabel"
                    data-ga4-event-name="navigation_menu"
                    data-ga4-action="Classes"
                    :data-ga4-details="props.gaLabel"
                >
                    {{ props.t.classesTitleCaseText }}
                </a>

                <ul
                    id="nav-classes"
                    class="text-left dropdown"
                >
                    <li>
                        <a
                            :href="'/' + props.currentUserLocale + '/pages/class'"
                            data-ga-category="Navigation Menu"
                            data-ga-action="Classes_About our Classes"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="navigation_menu"
                            data-ga4-action="Classes_About our Classes"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.aboutOurClassesText }}
                        </a>
                    </li>
                    <li v-if="props.classLocatorEnabled">
                        <a
                            :href="'/' + props.currentUserLocale + '/class_search' + (props.isCirclUrl ? '?class-type=circl-mobility' : '')"
                            data-ga-category="Navigation Menu"
                            data-ga-action="Classes_Find a Class"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="navigation_menu"
                            data-ga4-action="Classes_Find a Class"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.findAClassText }}
                        </a>
                    </li>
                    <template v-else>
                        <li>
                            <a
                                :href="'/' + props.currentUserLocale + '/party/nearMe?date=' + todayISO()"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Classes_Find a Class"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="navigation_menu"
                                data-ga4-action="Classes_Find a Class"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.findAnInPersonClassText }}
                            </a>
                        </li>
                        <li>
                            <a
                                :href="props.onlineClassBaseUrl + '/?locale=' + props.currentUserLocale.replace('-', '_')"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Classes_Find a Virtual Class"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="navigation_menu"
                                data-ga4-action="Classes_Find a Virtual Class"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.findAVirtualClassText }}
                            </a>
                        </li>
                    </template>

                    <li>
                        <a
                            :href="'/' + props.currentUserLocale + '/event/nearMe'"
                            data-ga-category="Navigation Menu"
                            data-ga-action="Classes_Find an Event"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="navigation_menu"
                            data-ga4-action="Classes_Find an Event"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.findAnEventText }}
                        </a>
                    </li>
                    <li>
                        <a
                            :href="'/' + props.currentUserLocale + '/instructor'"
                            data-ga-category="Navigation Menu"
                            data-ga-action="Classes_Find an Instructor"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="navigation_menu"
                            data-ga4-action="Classes_Find an Instructor"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.findAnInstructorText }}
                        </a>
                    </li>
                    <li>
                        <a
                            v-if="props.isBotBaiCoronavirusEnabled && !props.userHasBenefitSpecialtyTrainingAccess && props.isOnlineCountryUs"
                            :href="'/' + props.currentUserLocale + '/online-program'"
                            data-ga-category="Navigation Menu"
                            data-ga-action="Classes_Become an Instructor"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="navigation_menu"
                            data-ga4-action="Classes_Become an Instructor"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.becomeAnInstructorText }}
                        </a>
                        <a
                            v-else
                            :href="'/' + props.currentUserLocale + '/become-a-zumba-instructor'"
                            data-ga-category="Navigation Menu"
                            data-ga-action="Classes_Become an Instructor"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="navigation_menu"
                            data-ga4-action="Classes_Become an Instructor"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.becomeAnInstructorText }}
                        </a>
                    </li>
                    <li>
                        <a
                            v-if="props.isSixWeekLaunchEnabled && props.currentUserLocale == 'en-US' && !props.userHasBenefitSixWeekProgram"
                            :href="props.sixWeekSplashPageUrl"
                            target="_blank"
                            rel="noopener"
                            data-ga-category="Navigation Menu"
                            data-ga-action="Six Weeks Program Splash Page"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="navigation_menu"
                            data-ga4-action="Six Weeks Program Splash Page"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.splashPageMenuItemText }}
                        </a>
                        <a
                            v-else-if="props.isSixWeekLaunchEnabled && props.currentUserLocale == 'en-US' && props.userHasBenefitSixWeekProgram"
                            :href="'/' + props.currentUserLocale + '/6week'"
                            target="_blank"
                            rel="noopener"
                            data-ga-category="Navigation Menu"
                            data-ga-action="Six Weeks Program Splash Page"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="navigation_menu"
                            data-ga4-action="Six Weeks Program Splash Page"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.splashPageMenuItemText }}
                        </a>
                    </li>
                </ul>
            </li>

            <!-- ZUMBA APP -->
            <li
                v-if="props.isZumbaAppMenuLaunchEnabled && !props.isZumbaAppNoMenuButtonEnabled"
                class="gh-main-nav-item"
            >
                <a
                    class="gh-main-nav-link"
                    :href="props.appLandingUrl"
                    :title="props.t.zumbaAppTitleCaseText"
                    data-ga-category="Navigation Menu"
                    data-ga-action="Zumba App"
                    :data-ga-label="props.gaLabel"
                    data-ga4-event-name="navigation_menu"
                    data-ga4-action="Zumba App"
                    :data-ga4-details="props.gaLabel"
                >
                    {{ props.t.zumbaAppTitleCaseText }}
                </a>
                <ul
                    id="nav-trainings"
                    class="text-left dropdown"
                >
                    <li>
                        <a
                            :href="getAppUrl"
                            target="_blank"
                            rel="noopener"
                            data-ga-category="Navigation Menu"
                            data-ga-action="Zumba_App get_app"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="navigation_menu"
                            data-ga4-action="Zumba_App get_app"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.getTheAppTitleCaseText }}
                        </a>
                    </li>
                    <li>
                        <a
                            :href="props.virtualPlusSubscriptionUrl"
                            data-ga-category="Navigation Menu"
                            data-ga-action="Zumba_App subscription_url"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="navigation_menu"
                            data-ga4-action="Zumba_App subscription_url"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.virtualPlusClassesTitleCaseText }}
                        </a>
                    </li>
                </ul>
            </li>
            <li
                v-if="props.isZumbaAppNoMenuButtonEnabled"
                class="gh-main-nav-item"
            >
                <a
                    class="gh-main-nav-link"
                    :href="props.isZin ? '/zumba-app-zin' : '/app-subscription'"
                    :title="props.t.zumbaAppTitleCaseText"
                    data-ga-category="Navigation Menu"
                    data-ga-action="Zumba App"
                    :data-ga-label="props.gaLabel"
                    data-ga4-event-name="navigation_menu"
                    data-ga4-action="Zumba App"
                    :data-ga4-details="props.gaLabel"
                >
                    {{ props.t.zumbaAppTitleCaseText }}
                </a>
            </li>

            <!-- INSTRUCTOR TRAININGS -->
            <li class="gh-main-nav-item">
                <a
                    v-if="props.isBotBaiCoronavirusEnabled && !props.userHasBenefitSpecialtyTrainingAccess && props.isOnlineCountryUs"
                    class="gh-main-nav-link"
                    :href="'/' + props.currentUserLocale + '/online-program'"
                    data-ga-category="Navigation Menu"
                    data-ga-action="Instructor Trainings"
                    :data-ga-label="props.gaLabel"
                    data-ga4-event-name="navigation_menu"
                    data-ga4-action="Instructor Trainings"
                    :data-ga4-details="props.gaLabel"
                >
                    {{ props.t.instructorTrainingsDetailsText }}
                </a>
                <a
                    v-else
                    class="gh-main-nav-link"
                    :href="`/${props.currentUserLocale}/${props.userHasBenefitSpecialtyTrainingAccess ? 'training' : 'become-a-zumba-instructor'}`"
                    data-ga-category="Navigation Menu"
                    data-ga-action="Instructor Trainings"
                    :data-ga-label="props.gaLabel"
                    data-ga4-event-name="navigation_menu"
                    data-ga4-action="Instructor Trainings"
                    :data-ga4-details="props.gaLabel"
                >
                    {{ props.t.instructorTrainingsDetailsText }}
                </a>
                <ul
                    id="nav-trainings"
                    class="text-left dropdown"
                >
                    <template v-if="props.isBotBaiCoronavirusEnabled && !props.userHasBenefitSpecialtyTrainingAccess && props.isOnlineCountryUs">
                        <li>
                            <a
                                :href="'/' + props.currentUserLocale + '/online-program'"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Instructor Trainings_Become an Instructor"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="navigation_menu"
                                data-ga4-action="Instructor Trainings_Become an Instructor"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.becomeAnInstructorText }}
                            </a>
                        </li>
                        <li>
                            <a
                                :href="'/' + props.currentUserLocale + '/training/nearby?type%5B0%5D=zumba_basic_1&type%5B1%5D=jump_start_gold&type%5B2%5D=jump_start_kids'"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Instructor Find A Training"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="navigation_menu"
                                data-ga4-action="Instructor Find A Training"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.findATrainingText }}
                            </a>
                        </li>
                    </template>
                    <template v-else>
                        <li v-if="!props.userHasBenefitSpecialtyTrainingAccess">
                            <a
                                :href="'/' + props.currentUserLocale + '/become-a-zumba-instructor'"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Instructor Trainings_Become an Instructor"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="navigation_menu"
                                data-ga4-action="Instructor Trainings_Become an Instructor"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.becomeAnInstructorText }}
                            </a>
                        </li>
                        <li>
                            <a
                                :href="'/' + props.currentUserLocale + '/training/nearby?type%5B0%5D=zumba_basic_1&type%5B1%5D=jump_start_gold&type%5B2%5D=jump_start_kids'"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Instructor Find A Training"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="navigation_menu"
                                data-ga4-action="Instructor Find A Training"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.findATrainingText }}
                            </a>
                        </li>
                    </template>
                    <li>
                        <a
                            :href="'/' + props.currentUserLocale + '/trainings'"
                            data-ga-category="Navigation Menu"
                            data-ga-action="Instructor Trainings_View All Trainings"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="navigation_menu"
                            data-ga4-action="Instructor Trainings_View All Trainings"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{
                                props.isMaximizeb1SitenavTrainingtypesEnabled
                                    ? props.t.trainingTypesText
                                    : props.t.viewAllTrainingsText
                            }}
                        </a>
                    </li>
                    <Sessions
                        v-if="props.isZjRefactorNavigationEnabled"
                        :t="props.t"
                        :current-user-locale="props.currentUserLocale"
                        :ga-label="props.gaLabel"
                        :is-authenticated="props.isAuthenticated"
                    />
                </ul>
            </li>

            <!-- SHOP -->
            <li class="gh-main-nav-item">
                <a
                    class="gh-main-nav-link"
                    :href="props.shopUrl"
                    :title="props.t.shopText"
                    data-ga-category="Navigation Menu"
                    data-ga-action="Shop"
                    :data-ga-label="props.gaLabel"
                    data-ga4-event-name="navigation_menu"
                    data-ga4-action="Shop"
                    :data-ga4-details="props.gaLabel"
                    target="_blank"
                    rel="noopener"
                >
                    {{ props.t.shopText }}
                </a>
            </li>
        </ul>
    </header>
</template>

<script setup lang="ts">
import { reactive, onMounted, computed, PropType } from 'vue';
import MenuButton from '../nav/header/hamburger/Zumba/MenuButton.vue';
import Profile from '../Profile.vue';
import Sessions from './Sessions.vue';
import { todayISO } from '@ts/Util/datetime';
import { HeaderFooterStyle } from "@ts/Layout/header-footer";

const props = defineProps({
    t: {
        type: Object,
        default: () => ({
            navigationText: 'Navigation',
            myZinHomeText: 'My Zin Home',
            myDashboardText: 'My Dashboard',
            joinZinText: 'Join ZIN™',
            updatePaymentText: 'Update Payment',
            instructorTrainingsText: 'Instructor Trainings',
            sixWeekProgramText: '6 Week Program',
            myTrainingsText: 'My Trainings',
            manageMyAccountText: 'Manage My Account',
            myAccountText: 'My Account',
            logInText: 'Log In',
            signInText: 'Sign In',
            signOutText: 'Sign Out',
            myAnnouncementsText: 'My Announcements',
            marketingMaterialsText: 'Marketing Materials',
            findAnInstructorGymText: 'Find An Instructor',
            manageInstructorsText: 'Manage Instructors',
            hostATrainingText: 'Host a Training',
            zumbaForGymsText: 'Zumba For Gyms',
            gymAuditionKitText: 'Gym Audition Kit',
            manageLocationsText: 'Manage Locations',
            accountSummaryText: 'Account Summary',
            gymsProfileText: 'Gyms Profile',
            splashPageMenuItemText: '6 Week Program',
            classesTitleCaseText: 'Classes',
            aboutOurClassesText: 'About our Classes',
            findAnInPersonClassText: 'Find An In-Person Class',
            findAVirtualClassText: 'Find a Virtual Class',
            findAClassText: 'Find a Class',
            findAnEventText: 'Find an Event',
            findAnInstructorText: 'Find an Instructor',
            becomeAnInstructorText: 'Become an Instructor',
            shopText: 'Shop Clothing',
            instructorTrainingsDetailsText: 'Instructor Trainings',
            findATrainingText: 'Find a Training',
            trainingTypesText: 'Training Types',
            viewAllTrainingsText: 'View All Trainings',
            zinJamSessionsText: 'ZIN™ Jam Sessions',
            localClassesTitleCaseText: 'Local Classes',
            inPersonClassesTitleCaseText: 'In Person',
            zoomClassesTitleCaseText: 'Zoom',
            eventsTitleCaseText: 'Events',
            getTheAppTitleCaseText: 'Get the App',
            virtualClassesTitleCaseText: 'Virtual+ Classes',
            zumbaAppTitleCaseText: 'Zumba App',
            findInstructorsText: 'Find Instructors',
            hireAnInstructorText: 'Hire an Instructor',
            postAJobText: 'Post A Job',
        })
    },
    canAccessMemberMenu: {type: String, default: ''},
    canAccessSixWeeksProgramProfile: {type: String, default: ''},
    canConsumerViewDashboard: {type: String, default: ''},
    canGymOwnerViewDashboard: {type: String, default: ''},
    canManageGymProfile: {type: String, default: ''},
    canManageLinkedGymInstructors: {type: String, default: ''},
    currentUserLocale: {type: String, default: ''},
    gaLabel: {type: String, default: ''},
    gymActiveLink: {type: String, default: ''},
    headerFooterStyle: {type: String as PropType<HeaderFooterStyle>, default: 'full'},
    isAuthenticated: {type: String, default: ''},
    isBotBaiCoronavirusEnabled: {type: String, default: ''},
    isCmsShopBrandingZwEnabled: {type: String, default: ''},
    isMaximizeb1SitenavTrainingtypesEnabled: {type: String, default: ''},
    isOnlineCountryUs: {type: String, default: ''},
    isSixWeekLaunchEnabled: {type: String, default: ''},
    isSixWeekTopNavLinkEnabled: {type: String, default: ''},
    isTrainedNonMember: {type: String, default: ''},
    itemClass: {type: String, default: ''},
    linkClass: {type: String, default: ''},
    localizationUser: {type: String, default: ''},
    loginUrl: {type: String, default: ''},
    signUpUrl: {type: String, default: ''},
    logoSrc: {type: String, default: ''},
    logoSrcSet: {type: String, default: ''},
    mtvHeaderEnabled: {type: String, default: ''},
    navigationText: {type: String, default: 'Navigation'},
    onlineClassBaseUrl: {type: String, default: ''},
    profileName: {type: String, default: ''},
    shopUrl: {type: String, default: ''},
    shouldShowJoinLink: {type: String, default: ''},
    userHasDelinquentMembership: {type: String, default: ''},
    sixWeekSplashPageUrl: {type: String, default: ''},
    userHasBenefitMemberPortal: {type: String, default: ''},
    userHasBenefitSixWeekProgram: {type: String, default: ''},
    userHasBenefitSpecialtyTrainingAccess: {type: String, default: ''},
    zinResponsiveLink: {type: String, default: ''},
    isZjRefactorNavigationEnabled: {type: String, default: ''},
    isSupportedSixWeekLocale: {type: String, default: ''},
    classLocatorEnabled: {type: String, default: ''},
    isCirclUrl: {type: String, default: ''},
    isBaiUrl: {type: String, default: ''},
    isTrainingUrl: {type: String, default: ''},
    isZumbaAppMenuLaunchEnabled: {type: String, default: ''},
    isZumbaAppNoMenuButtonEnabled: {type: Boolean, default: false},
    isZin: {type: Boolean, default: false},
    appStoreUrl: {type: String, default: ''},
    playStoreUrl: {type: String, default: ''},
    downloadAppUrl: {type: String, default: ''},
    appLandingUrl: {type: String, default: ''},
    virtualPlusSubscriptionUrl: {type: String, default: ''},
    isPrePaymentAccessEnabled: {type: String, default: ''},
    isJobBoardFormEnabled: {type: String, default: ''},
    hideMobileMenu: {type: Boolean, default: false},
    isGymPostAJobEnabled: {type: Boolean, default: false}
});

const state = reactive({
    isSticky: false
})

const hideNavigationElements = computed((): boolean => {
    return props.headerFooterStyle === 'minimal'
})

const getAppUrl = computed(() => {
    let url = props.downloadAppUrl
    const isIos = navigator.userAgent.match(/iP(ad|hone|od)/i) !== null;
    const isAndroid = navigator.userAgent.match(/Android/i) !== null;

    if (isAndroid) {
        url = props.playStoreUrl;
    }
    if (isIos) {
        url = props.appStoreUrl;
    }
    return url;
});

onMounted(() => {
    addStickyObserver();
})

const addStickyObserver = () => {
    const headerElement = document.getElementById('header-toggle-component');
    const observerOptions = { threshold: 0.1 };
    const toggleIsSticky = entries => state.isSticky = entries[0].intersectionRatio < 0.1;
    const observer = new IntersectionObserver(toggleIsSticky, observerOptions);
    if (!headerElement) return;
    observer.observe(headerElement);
}

</script>

<style scoped>
a {
    text-decoration: none;
}
.gh-secondary-nav {
    list-style: none;
    display: block;
}
.gh-secondary-nav > li {
    float: left;
}
.lte9 .sticky {
    top: 0;
}
.hide-sticky {
    display: none;
}
.global-header {
    float: left;
    padding: 1em 0 0;
    width: 100%;
    height: var(--global-header-mobile-height, 4.813em);
    border-bottom: solid 2px #eff0f6;
    background: #fff;
}
.global-header .header-top {
    height: 80px;
    position: relative;
    z-index: 999;
}
:slotted(.gh-logo) {
	display: block;
	position: absolute;
	left: 54%;
	margin-left: -75px;
	margin-top: 0.3em;
    width: 120px;
}
:slotted(.lte9 .gh-logo) {
	display: block;
    float: left;
    position: relative;
    width: 120px;
}
.gh-logo {
    display: block;
    position: absolute;
    left: 54%;
    margin-left: -75px;
    margin-top: 0.3em;
    width: 120px;
}
.lte9 .gh-logo {
    display: block;
    float: left;
    position: relative;
    width: 120px;
}
.gh-menu-left, .gh-menu-right {
    float: left;
    position: relative;
}
.gh-menu-right {
    float: right;
}
.icon-hamburger-svg, .gh-nav-trigger {
    background: url("~@icons/icon-sprite.svg?external") no-repeat;
    background-size: 923px 86px;
    background-position: -252px 0;
    width: 35px;
    height: 30px;
}
.gh-nav-trigger {
    left: 1em;
    position: absolute;
    z-index: 1;
    top: 0.375em;
    text-indent: 9999px;
}
.lte9 .gh-nav-trigger {
    display: none;
}
.lang-ko-KR .gh-nav-trigger {
    text-indent: -9999px;
}
.gh-secondary-nav {
    margin-top: 0.75em;
}
.gh-secondary-nav > li {
    position: relative;
}
.gh-main-nav-item, .gh-secondary-nav-item {
    margin-left: 1.5em;
    position: relative;
}
.gh-main-nav-item:hover, .gh-secondary-nav-item:hover {
    padding-bottom: 1.2em;
}
.gh-main-nav-item:hover .dropdown, .gh-secondary-nav-item:hover .dropdown, .gh-main-nav-item:hover .dropdown-mtv, .gh-secondary-nav-item:hover .dropdown-mtv {
    display: block;
    left: 0;
}
.gh-main-nav-item:hover .dropdown.profile-dropdown, .gh-secondary-nav-item:hover .dropdown.profile-dropdown, .gh-main-nav-item:hover .dropdown-mtv.profile-dropdown, .gh-secondary-nav-item:hover .dropdown-mtv.profile-dropdown {
    top: 1.9em !important;
    right: -1.2em;
    left: auto !important;
}
.gh-main-nav-item:hover .dropdown.profile-dropdown:before, .gh-secondary-nav-item:hover .dropdown.profile-dropdown:before, .gh-main-nav-item:hover .dropdown-mtv.profile-dropdown:before, .gh-secondary-nav-item:hover .dropdown-mtv.profile-dropdown:before {
    right: 1em;
    left: auto;
}
.lte9 .gh-main-nav-item, .lte9 .gh-secondary-nav-item {
    float: left;
    margin-left: 30px;
}
.gh-main-nav-item {
    margin-left: 2.2em;
}
.six-week-program__button {
    bottom: 1rem;
}
.gh-main-nav-link {
    display: block;
    font-size: 0.875rem;
    font-family: 'Zandes-Bold', Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    color: #2c2d37;
}
.gh-main-nav-link:hover {
    color: #2c2d37;
}
.gh-main-nav > li {
    float: left;
}
.gh-main-nav {
    list-style: none;
    display: none;
}
.dropdown {
    padding: 0.5em 0;
    border: 0;
    background: #d43361;
    -webkit-border-radius: 0.2em;
    -moz-border-radius: 0.2em;
    -ms-border-radius: 0.2em;
    -o-border-radius: 0.2em;
    border-radius: 0.2em;
    z-index: 10000;
    margin-top: 1em;
    text-align: left;
    width: 12.5em;
    left: 0;
    top: 2.1em;
    display: none;
    position: absolute;
    left: -9999px;
    list-style: none;
    margin-left: 0;
    max-height: none;
    height: auto;
    margin-top: 2px;
}
.dropdown:before {
    content: '';
    top: -1em;
    border: solid 9px;
    border-color: transparent transparent #d43361 transparent;
    position: absolute;
    left: 1em;
}
.dropdown:after {
    content: none;
    display: none;
}
.dropdown li a {
    padding: 1em 1.25em;
    font-family: 'Zandes-Bold', Helvetica, Arial, sans-serif;
    font-size: 0.8125rem;
    color: #fff;
    text-transform: uppercase;
    line-height: 1em;
    display: block;
    cursor: pointer;
}
.dropdown li a:hover {
    background: #2c2d37;
}
.button {
    margin: 0;
    padding: 1rem 2rem 0.77em;
    -webkit-border-radius: 0.2em;
    -moz-border-radius: .2em;
    -ms-border-radius: .2em;
    -o-border-radius: .2em;
    border-radius: 0.2em;
    background: #d43361;
    font-family: "Zandes-Bold", Helvetica, Arial, sans-serif;
    font-style: normal;
    text-transform: uppercase;
    font-size: .9375rem;
}
.is-green {
    background: var(--zumba-neon-green);
    color: #1d1d24;
}
.no-clickable {
    pointer-events: none
}
@media only screen and (max-width: 47.9375em) {
    .hide-sticky {
        display: block;
    }
}
@media only screen and (min-width:48em) {
    .global-header {
        padding: 1em 0 0;
        height: var(--global-header-desktop-height, 5.125em);
    }
    .gh-nav-trigger {
        left: 2.125em;
        top: 0.6875em;
    }
}
@media only screen and (min-width:64em) {
    .global-header {
        z-index: 100;
        padding: 1em 1.5em 0;
        height: var(--global-header-desktop-height, 5.125em);
        text-align: right;
    }
    .gh-secondary-nav {
        margin-top: 1.3em;
        margin-left: 0;
    }
    .gh-secondary-nav > li {
        margin-left: 1.9em;
    }
    .gh-nav-trigger {
        display: none;
    }
    .gh-logo {
        float: left;
        position: relative;
        left: 0;
        margin-left: 0;
        width: 105px;
        height: 34px;
    }
	:slotted(.gh-logo) {
		float: left;
        position: relative;
        left: 0;
        margin-left: 0;
        margin-top: .5rem;
        width: 105px;
        height: 34px;
	}
    .sticky {
        position: fixed;
        top: 0;
    }
    .gh-main-nav {
        margin: 1.125em 0 0 0;
        display: block;
        float: right;
    }
}
</style>
