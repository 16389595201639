<template>
    <template v-if="props.isAuthenticated">
        <li
            v-if="!props.isGuestUser"
            class="ocn-list-item"
        >
            <a
                v-if="!props.canAccessMemberMenu && props.canConsumerViewDashboard"
                :href="'/' + props.currentUserLocale + '/training/history'"
                class="ocn-list-link"
                :class="{'js-active-item active-item': props.active?.['/training/history']}"
                data-ga-category="Navigation Menu"
                data-ga-action="Trainings"
                :data-ga-label="props.gaLabel"
            >
                {{
                    props.dashboardMenuItemsEnabled && props.canAccessMemberMenu
                        ? props.t.myTrainingsProgramText
                        : props.t.myTrainingsText
                }}
            </a>
            <!-- eslint-disable vue/no-v-html -->
            <a
                v-if="props.canAccessMemberMenu && !props.hasPreMembershipLinksOnly"
                class="ocn-list-link"
                :class="{'js-active-item active-item': props.active?.['/dashboard']}"
                :href="'/' + props.currentUserLocale + '/dashboard'"
                data-ga-category="Navigation Menu"
                data-ga-action="ZIN Dashboard"
                :data-ga-label="props.gaLabel"
                v-html="props.t.zinHomeText"
            />
            <!--eslint-enable-->
            <a
                v-else-if="props.userHasDelinquentMembership"
                class="ocn-list-link"
                :class="{'js-active-item active-item': props.active?.['/student_dashboard']}"
                :href="'/' + props.currentUserLocale + '/student_dashboard'"
                data-ga-category="Navigation Menu"
                data-ga-action="My Dashboard"
                :data-ga-label="props.gaLabel"
            >
                {{ props.t.myDashboardText }}
            </a>
            <a
                v-else-if="((props.canManageGymProfile && props.canGymOwnerViewDashboard) || (props.canConsumerViewDashboard && props.canAccessMemberMenu))"
                class="ocn-list-link"
                :class="{'js-active-item active-item': props.active?.['/dashboard']}"
                :href="'/' + props.currentUserLocale + '/dashboard'"
                data-ga-category="Navigation Menu"
                data-ga-action="My Dashboard"
                :data-ga-label="props.gaLabel"
            >
                {{ props.t.myDashboardText }}
            </a>
            <a
                v-if="props.hasPendingFirstTraining"
                class="ocn-list-link"
                :class="{'js-active-item active-item': props.active?.['/dashboard']}"
                :href="'/' + props.currentUserLocale + '/training/history'"
                data-ga-category="Navigation Menu"
                data-ga-action="Training History"
                :data-ga-label="props.gaLabel"
            >
                {{ props.t.myTrainingText }}
            </a>
            <!-- eslint-disable vue/no-v-html -->
            <a
                v-if="props.shouldShowJoinLink"
                class="ocn-list-link"
                :href="'/' + props.currentUserLocale + '/dashboard'"
                data-ga-category="Navigation Menu"
                data-ga-action="Join ZIN"
                :data-ga-label="props.gaLabel"
                v-html="props.t?.joinZinText"
            />
            <!-- eslint-enable vue/no-v-html -->
            <a
                v-else-if="props.userHasDelinquentMembership"
                class="ocn-list-link"
                :href="'/' + props.currentUserLocale + (props.isPrePaymentAccessEnabled ? '/membership/pay' : '/dashboard')"
                data-ga-category="Navigation Menu"
                data-ga-action="Update Payment"
                :data-ga-label="props.gaLabel"
            >
                {{ props.t.updatePaymentText }}
            </a>
        </li>
        <li
            v-if="!props.userHasDelinquentMembership && props.canAccessMemberMenu"
            class="ocn-list-item"
        >
            <a
                class="more-below ocn-list-link"
                :class="{
                    'js-active-item inactive-item': !isKeyEmpty(props.active?.['Tools']) || openTools || openClassManagement || openNestedSubmenu,
                }"
                href="#"
                :title="props.t.toolText"
            >
                {{ props.t.toolText }}
            </a>
            <ul
                class="ocn-subnav"
                :style="(!isKeyEmpty(props.active?.['Tools']) || openTools || openClassManagement || openNestedSubmenu) ? 'display: block;': ''"
            >
                <li class="ocn-list-item">
                    <a
                        v-if="(props.isBenefitMembershipStatusCardEnabled && props.userHasBenefitMembershipStatusCard)"
                        class="ocn-list-link"
                        :class="{'js-active-item active-item': props.active?.['/membership/status_card' + props.membershipTypeSlug.toLowerCase()]}"
                        :href="'/' + props.currentUserLocale + '/membership/status_card/' + props.membershipTypeSlug.toLowerCase()"
                        :title="props.t.statusCardText"
                    >
                        {{ props.t.statusCardText }}
                    </a>
                </li>
                <li
                    v-if="props.canAccessDcd"
                    class="ocn-list-item"
                >
                    <!-- eslint-disable vue/no-v-html -->
                    <a
                        id="hamburger-link-learn-zin-media"
                        :href="'/' + props.currentUserLocale + '/now'"
                        class="ocn-list-link"
                        :class="{'js-active-item active-item': props.active?.['/now']}"
                        data-ga-category="Navigation Menu"
                        data-ga-action="Now"
                        :data-ga-label="props.gaLabel"
                        v-html="props.t.zinNowText"
                    />
                    <!-- eslint-enable -->
                </li>
                <OnHold
                    :t="props.t"
                    :current-user-locale="props.currentUserLocale"
                    :is-on-hold="props.isOnHold"
                    :on-hold-end="props.onHoldEnd"
                />
                <li
                    v-if="props.canAccessMemberMenu && !props.classManagementEnabled"
                    class="ocn-list-item"
                >
                    <span
                        v-if="props.hasPreMembershipLinksOnly"
                        class="ocn-list-item__no-access ocn-list-link"
                    >
                        {{ props.t.manageClassesText }}
                    </span>
                    <a
                        v-else
                        :href="props.manageClassesAction"
                        class="ocn-list-link"
                        :class="{'js-active-item active-item': props.manageClassesActive}"
                        data-ga-category="Navigation Menu"
                        data-ga-action="Tools_My Classes"
                        :data-ga-label="props.gaLabel"
                    >
                        {{ props.t.manageClassesText }}
                    </a>
                </li>
                <li
                    v-if="props.canAccessMemberMenu && props.classManagementEnabled"
                    class="ocn-list-item"
                >
                    <span
                        v-if="props.hasPreMembershipLinksOnly"
                        class="ocn-list-item__no-access ocn-list-link"
                    >
                        {{ props.t.manageClassesText }}
                    </span>
                    <a
                        v-if="!props.hasPreMembershipLinksOnly"
                        :href="'/' + props.currentUserLocale + '/member/class_management'"
                        class="ocn-list-link more-below with-new-feature-badge"
                        :class="{
                            'js-active-item inactive-item': !isKeyEmpty(props.active?.['ClassManagement']) || openClassManagement,
                            'pre-selected-active': !isKeyEmpty(props.active?.['ClassManagement']) || openClassManagement
                        }"
                    >
                        <span class="new-feature-item">
                            {{ props.t.classManagement }}
                        </span>
                        <span class="new-feature-tag-wrap">
                            <Tag
                                variety="small"
                                :show-cancel="false"
                                class="new-feature-tag"
                            >
                                {{ props.t.newFeature }}
                            </Tag>
                        </span>
                    </a>
                    <ul
                        v-if="!props.hasPreMembershipLinksOnly"
                        class="ocn-subnav subnav-nested"
                        :style="!isKeyEmpty(props.active?.['ClassManagement']) || openClassManagement ? 'display: block;': ''"
                    >
                        <li class="ocn-list-item">
                            <a
                                :href="'/' + props.currentUserLocale + '/member/class_management'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['ClassManagement']?.['/member/class_management']}"
                                data-ga-category="Class Management"
                                data-ga-action="My Classes"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.myClasses }}
                            </a>
                        </li>
                        <li class="ocn-list-item">
                            <a
                                :href="'/' + props.currentUserLocale + '/member/instructor_page/view'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['ClassManagement']?.['/member/instructor_page/view']}"
                                data-ga-category="Class Management"
                                data-ga-action="Instructor Page"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.instructorPageText }}
                            </a>
                        </li>
                        <li class="ocn-list-item">
                            <a
                                :href="'/' + props.currentUserLocale + '/member/packages'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['ClassManagement']?.['/member/packages']}"
                                data-ga-category="Class Management"
                                data-ga-action="Class Packs"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.classPacks }}
                            </a>
                        </li>
                        <li class="ocn-list-item">
                            <a
                                :href="'/' + props.currentUserLocale + '/member/class_management/students'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['ClassManagement']?.['/member/class_management/students']}"
                                data-ga-category="Class Management"
                                data-ga-action="Students"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.students }}
                            </a>
                        </li>
                        <li class="ocn-list-item">
                            <a
                                :href="'/' + props.currentUserLocale + '/member/class_management/settings'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['ClassManagement']?.['/member/class_management/settings']}"
                                data-ga-category="Class Management"
                                data-ga-action="Settings"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.settings }}
                            </a>
                        </li>
                    </ul>
                </li>
                <li
                    v-if="(props.canAccessMemberMenu && props.memberProfileEnabled && props.userHasBenefitInstructorProfile) && !openClassManagement"
                    class="ocn-list-item"
                    mtv-strong-hide
                >
                    <span
                        v-if="props.hasPreMembershipLinksOnly"
                        class="ocn-list-item__no-access ocn-list-link"
                    >
                        {{ props.t.instructorPageText }}
                    </span>
                    <a
                        v-else
                        :href="'/' + props.currentUserLocale + '/member/instructor_page/view'"
                        class="ocn-list-link"
                        :class="{'js-active-item active-item': props.active?.['Tools']?.['/member/website_forward']}"
                        data-ga-category="Navigation Menu"
                        data-ga-action="Instructor_Page"
                        :data-ga-label="props.gaLabel"
                    >
                        {{ props.t.instructorPageText }}
                    </a>
                </li>
                <li
                    v-if="(props.canAccessMemberMenu && props.memberProfileEnabled && props.userHasBenefitInstructorProfile)"
                    class="ocn-list-item"
                    mtv-zumba-hide
                >
                    <a
                        :href="'/' + props.currentUserLocale + '/member/profile'"
                        class="ocn-list-link"
                        data-ga-category="Navigation Menu"
                        data-ga-action="Instructor_Page"
                        :data-ga-label="props.gaLabel"
                    >
                        {{ props.t.instructorPageText }}
                    </a>
                </li>
                <li
                    v-if="(props.canAccessMemberMenu && props.canManageJamSessions)"
                    class="ocn-list-item"
                >
                    <a
                        :href="'/' + props.currentUserLocale + '/zj/session/my'"
                        class="ocn-list-link"
                        :class="{'js-active-item active-item': props.active?.['Tools']?.['/zj/session/my']}"
                        data-ga-category="Navigation Menu"
                        data-ga-action="Tools_My ZJ Sessions"
                        :data-ga-label="props.gaLabel"
                    >
                        {{ props.t.myZjSessionsText }}
                    </a>
                </li>
                <li
                    v-if="(props.canAccessMemberMenu && props.userHasBenefitManageZinEvents)"
                    class="ocn-list-item"
                >
                    <span
                        v-if="props.hasPreMembershipLinksOnly"
                        class="ocn-list-item__no-access ocn-list-link"
                    >
                        {{ props.t.myEventsText }}
                    </span>
                    <a
                        v-else
                        :href="'/' + props.currentUserLocale + '/member/event/manage'"
                        class="ocn-list-link"
                        :class="{'js-active-item active-item': props.active?.['Tools']?.['/member/event/manage']}"
                        data-ga-category="Navigation Menu"
                        data-ga-action="Tools_My Events"
                        :data-ga-label="props.gaLabel"
                    >
                        {{ props.t.myEventsText }}
                    </a>
                </li>
                <li
                    v-if="!props.isGuestUser"
                    class="ocn-list-item"
                >
                    <a
                        :href="'/' + props.currentUserLocale + '/training/history'"
                        class="ocn-list-link"
                        :class="{'js-active-item active-item': props.active?.['/training/history']}"
                        data-ga-category="Navigation Menu"
                        data-ga-action="Trainings"
                        :data-ga-label="props.gaLabel"
                    >
                        {{
                            props.dashboardMenuItemsEnabled
                                ? props.t.myTrainingsProgramText
                                : props.t.myTrainingsText
                        }}
                    </a>
                </li>
                <li
                    v-if="props.canAccessMemberMenu && props.canAccessEducationSpecialistMenu"
                    class="ocn-list-item"
                >
                    <a
                        class="more-below ocn-list-link"
                        :class="{
                            'js-active-item inactive-item': !isKeyEmpty(props.active?.['ZES Support']),
                        }"
                        :href="'/' + props.currentUserLocale + '/support'"
                    >
                        {{ props.t.zesLinksText }}
                    </a>
                    <ul
                        class="ocn-subnav subnav-nested"
                        :style="!isKeyEmpty(props.active?.['ZES Support']) ? 'display: block;': ''"
                    >
                        <li class="ocn-list-item">
                            <a
                                :href="'/' + props.currentUserLocale + '/es/training_status'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/es/training_status'])}"
                                data-ga-category="Navigation Menu"
                                data-ga-action="ZES Links_Instructor Training Status"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.instructorTrainingStatusText }}
                            </a>
                        </li>
                        <li
                            v-if="props.isDgfPublicEnabled"
                            class="ocn-list-item"
                        >
                            <a
                                :href="'/' + props.currentUserLocale + '/es/training_status/attendance'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/es/training_status/attendance'])}"
                                :data-ga-category="props.membershipTypeSlug + ' Home'"
                                data-ga-action="Training Attendance"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.trainingAttendanceText }}
                            </a>
                        </li>
                        <li
                            v-if="props.canManageTrainingLocations"
                            class="ocn-list-item"
                        >
                            <a
                                :href="'/' + props.currentUserLocale + '/es/location/training_index'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/es/location/training_index'])}"
                                data-ga-category="Navigation Menu"
                                data-ga-action="ZES Links_Training Location Management"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.trainingLocationManagementText }}
                            </a>
                        </li>
                        <li
                            v-if="props.canRequestTrainings"
                            class="ocn-list-item"
                        >
                            <a
                                :href="'/' + props.currentUserLocale + '/es/training_request'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/es/training_request'])}"
                                data-ga-category="Navigation Menu"
                                data-ga-action="ZES Links_Training Request Form"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.trainingRequestFormText }}
                            </a>
                        </li>
                        <li class="ocn-list-item">
                            <a
                                :href="'/' + props.currentUserLocale + '/es/training_registration/add'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/es/training_registration/add'])}"
                                data-ga-category="Navigation Menu"
                                data-ga-action="ZES Links_Training Registration"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.trainingRegistrationText }}
                            </a>
                        </li>
                        <li
                            v-if="props.canAccessEducationSpecialistDocuments"
                            class="ocn-list-item"
                        >
                            <a
                                :href="'/' + props.currentUserLocale + '/es/zes_document'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/es/zes_document'])}"
                                data-ga-category="Navigation Menu"
                                data-ga-action="ZES Links_ZES Docs"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.zesDocsText }}
                            </a>
                        </li>
                        <li
                            v-if="props.canRequestTrainingMaterials"
                            class="ocn-list-item"
                        >
                            <a
                                :href="'/' + props.currentUserLocale + '/es/training_material'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/es/training_material'])}"
                                data-ga-category="Navigation Menu"
                                data-ga-action="ZES Links_Training Material Request"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.trainingMaterialRequestText }}
                            </a>
                        </li>
                        <li
                            v-if="(props.canAccessEducationSpecialistMenu && props.botPublicZesLinkEnabled)"
                            class="ocn-list-item"
                        >
                            <a
                                :href="'/' + props.currentUserLocale + '/training/online/trainingTypeSlug:zumba_basic_1'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/es/training_material'])}"
                                data-ga-category="Navigation Menu"
                                data-ga-action="ZES Links_Training Material Request"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.zumbaInstructorLicensingProgramText }}
                            </a>
                        </li>
                    </ul>
                </li>
                <li
                    v-if="props.canAccessMemberMenu"
                    class="ocn-list-item"
                >
                    <a
                        class="more-below ocn-list-link"
                        :class="{
                            'js-active-item inactive-item': !isKeyEmpty(props.active?.['Teach']),
                            'pre-selected-active': !isKeyEmpty(props.active?.['Teach'])
                        }"
                        href="#"
                    >
                        {{ props.t.teachingPromotionText }}
                    </a>
                    <ul
                        class="ocn-subnav subnav-nested"
                        :style="!isKeyEmpty(props.active?.['Teach']) ? 'display: block;': ''"
                    >
                        <li
                            v-if="props.userHasBenefitManageZjSession"
                            class="ocn-list-item"
                        >
                            <a
                                :href="'/' + props.currentUserLocale + '/trainer/session/my'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Teach']?.['/trainer/session/my']}"
                            >
                                {{ props.t.manageZjSessionsText }}
                            </a>
                        </li>
                        <li class="ocn-list-item">
                            <span
                                v-if="props.hasPreMembershipLinksOnly"
                                class="ocn-list-item__no-access ocn-list-link"
                            >
                                {{ props.t.userMenuMarketingMaterialsText }}
                            </span>
                            <a
                                v-else
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Teach']?.['/marketing_materials']}"
                                target="_blank"
                                rel="noopener"
                                :href="'/' + props.currentUserLocale + '/marketing_materials'"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Teaching Promotion_Marketing Materials"
                                :data-ga-label="props.gaLabel"
                                @click="() => track('MM: Downloads', {})"
                            >
                                {{ props.t.userMenuMarketingMaterialsText }}
                            </a>
                        </li>
                        <li class="ocn-list-item">
                            <a
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Teach']?.['/member/choreography']}"
                                :href="'/' + props.currentUserLocale + '/member/choreography'"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Teaching Promotion_Choreo Notes"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.choreographyNotesText }}
                            </a>
                        </li>
                        <li
                            class="ocn-list-item"
                            mtv-strong-hide
                        >
                            <a
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Teach']?.['/zinplay']}"
                                :href="'/' + props.currentUserLocale + '/zinplay'"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Teaching Promotion_ZIN Play App"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.zinPlayAppText }}
                            </a>
                        </li>
                        <li
                            v-if="props.isClassBuzzSplashEnabled"
                            class="ocn-list-item"
                            mtv-strong-hide
                        >
                            <a
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Teach']?.['/classbuzz']}"
                                :href="'/' + props.currentUserLocale + '/classbuzz'"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Classbuzz_App"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.classBuzzAppText }}
                            </a>
                        </li>
                    </ul>
                </li>
                <li
                    v-if="props.canAccessMemberMenu"
                    class="ocn-list-item"
                >
                    <a
                        class="more-below ocn-list-link"
                        :class="{
                            'js-active-item inactive-item': !isKeyEmpty(props.active?.['Learn']),
                            'pre-selected-active': !isKeyEmpty(props.active?.['Learn'])
                        }"
                        href="#"
                    >
                        {{ props.t.trainingsEducationText }}
                    </a>
                    <ul
                        class="ocn-subnav subnav-nested"
                        :style="!isKeyEmpty(props.active?.['Learn']) ? 'display: block;': ''"
                    >
                        <li class="ocn-list-item">
                            <span
                                v-if="props.hasPreMembershipLinksOnly && props.restrictedInstructorTraining"
                                class="ocn-list-item__no-access ocn-list-link"
                            >
                                {{ props.t.instructorTrainingsText }}
                            </span>
                            <a
                                v-else
                                :href="'/' + props.currentUserLocale + '/training'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Learn']?.['/training']}"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Trainings Education_Instructor Trainings"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.instructorTrainingsText }}
                            </a>
                        </li>
                        <li
                            v-if="props.isMembershipCirclInstructorNavigationEnabled"
                            class="ocn-list-item"
                        >
                            <a
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Learn']?.['/circl-mobility-instructor']}"
                                :href="'/' + props.currentUserLocale + '/circl-mobility-instructor'"
                                data-ga-category="ZUMBA Home"
                                :data-ga-action="`${
                                    $props.isMembershipTypeZumba ? 'CIRCL_Mobility_ZIN' : 'CIRCL_Mobility_SYNC'}`"
                            >
                                <span
                                    v-if="props.isMembershipCirclInstructorNavigationNewEnabled && !props.isNoomCertificationEnabled"
                                    class="new-feature-item"
                                >
                                    CIRCL Mobility™
                                </span>
                                <span
                                    v-if="props.isMembershipCirclInstructorNavigationNewEnabled && !props.isNoomCertificationEnabled"
                                    class="new-feature-tag-wrap"
                                >
                                    <Tag
                                        variety="small"
                                        :show-cancel="false"
                                        class="new-feature-tag"
                                    >
                                        {{ props.t.newFeature }}
                                    </Tag>
                                </span>
                                <span
                                    v-if="!props.isMembershipCirclInstructorNavigationNewEnabled || props.isNoomCertificationEnabled"
                                >
                                    CIRCL Mobility™
                                </span>
                            </a>
                        </li>
                        <li
                            v-if="props.userHasBenefitStrongInstructorAccess"
                            class="ocn-list-item"
                            mtv-strong-hide
                        >
                            <a
                                class="ocn-list-link"
                                href="https://strong.zumba.com/en-US/become-an-instructor"
                                data-ga-category="ZUMBA Home"
                                data-ga-action="STRONG_Nation_become_an_instructor"
                            >
                                STRONG Nation™
                            </a>
                        </li>
                        <li
                            class="ocn-list-item"
                        >
                            <a
                                v-if="props.isMenuLinksEnabled && hasPreMembershipLinksOnly"
                                :href="'/' + props.currentUserLocale + '/mentor'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Learn']?.['/mentor']}"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Trainings Education_Mentor Program"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.mentorProgramText }}
                            </a>
                            <a
                                v-else-if="props.isMenuLinksEnabled && props.userHasBenefitUseZinMentorProgram"
                                :href="'/' + props.currentUserLocale + '/mentor/dashboard'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Learn']?.['/mentor/dashboard']}"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Trainings Education_Mentor Program"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.mentorProgramText }}
                            </a>
                        </li>
                        <li
                            v-if="props.isZinHomeAcademyLinksEnabled"
                            class="ocn-list-item"
                        >
                            <a
                                class="ocn-list-link"
                                href="//convention.zumba.com"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Trainings Education_Convention"
                                :data-ga-label="props.gaLabel"
                            >{{ props.t.zumbaConventionText }} </a>
                        </li>
                        <li
                            v-if="props.isZinHomeAcademyLinksEnabled"
                            class="ocn-list-item"
                        >
                            <a
                                class="ocn-list-link"
                                href="//academies.zumba.com"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Trainings Education_Academies"
                                :data-ga-label="props.gaLabel"
                            >{{ props.t.instructorAcademiesText }}</a>
                        </li>
                        <li
                            v-if="props.userHasBenefitMemberELearning && !props.hideJapaneseMenu"
                            class="ocn-list-item"
                        >
                            <span
                                v-if="props.hasPreMembershipLinksOnly"
                                class="ocn-list-item__no-access ocn-list-link"
                            >
                                {{ props.t.eLearningText }}
                            </span>
                            <a
                                v-else
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Learn']?.['/member/elearning']}"
                                :href="'/' + props.currentUserLocale + '/member/elearning'"
                            >
                                {{ props.t.eLearningText }}
                            </a>
                        </li>
                        <template
                            v-if="!props.hideJapaneseMenu"
                        >
                            <li
                                v-if="(props.isAfaaDashboardEnabled && props.hasPreMembershipLinksOnly)"
                                class="ocn-list-item"
                            >
                                <span class="ocn-list-item__no-access ocn-list-link">
                                    {{ props.t.afaaGfiForZinText }}
                                </span>
                            </li>
                            <li
                                v-else-if="props.isAfaaDashboardEnabled"
                                class="ocn-list-item"
                            >
                                <a
                                    :href="'/' + props.currentUserLocale + '/member/afaa'"
                                    class="ocn-list-link"
                                    :class="{'js-active-item active-item': props.active?.['Learn']?.['/member/afaa']}"
                                    :data-ga-category="props.membershipTypeSlug + ' Home'"
                                    data-ga-action="AFAA"
                                >
                                    {{ props.t.afaaGfiForZinText }}
                                </a>
                            </li>
                        </template>
                        <li
                            v-if="showNoom"
                            class="ocn-list-item"
                        >
                            <span
                                v-if="(props.isNoomCertificationEnabled && props.hasPreMembershipLinksOnly)"
                                class="ocn-list-item__no-access ocn-list-link"
                            >
                                {{ props.t.noomCoachingText }}
                            </span>
                            <slot
                                v-else-if="props.isNoomCertificationEnabled"
                                name="noom-link"
                            />
                        </li>
                        <li
                            v-if="(props.isMembershipTypeZumba && props.isPoseSideMenuEnabled && props.userHasBenefitPoseChallengeAccess)"
                            class="ocn-list-item"
                            mtv-strong-hide
                        >
                            <a
                                :href="'/' + props.currentUserLocale + '/step-challenge'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Learn']?.['/step-challenge']}"
                                data-ga-category="ZUMBA Home"
                                data-ga-action="Step_Challenge"
                            >
                                {{ props.t.stepChallengeText }}
                            </a>
                        </li>
                    </ul>
                </li>
                <li
                    v-if="props.canAccessMemberMenu"
                    class="ocn-list-item"
                >
                    <a
                        class="more-below ocn-list-link"
                        :class="{
                            'js-active-item inactive-item': !isKeyEmpty(props.active?.['Earn']),
                            'pre-selected-active': !isKeyEmpty(props.active?.['Earn'])
                        }"
                        href="#"
                    >
                        {{ props.t.earningsSupportText }}
                    </a>
                    <ul
                        v-if="props.isTippingLandingLinkLeftMenuEnabled"
                        class="ocn-subnav subnav-nested"
                        :style="!isKeyEmpty(props.active?.['Earn']) ? 'display: block;': ''"
                    >
                        <li class="ocn-list-item">
                            <a
                                :href="'/' + props.currentUserLocale + '/member/tipping'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Earn']?.['/member/tipping']}"
                                data-ga-category="Member Home"
                                data-ga-action="Tipping"
                            >
                                {{ props.t.tippingText }}
                            </a>
                        </li>
                    </ul>
                    <ul
                        class="ocn-subnav subnav-nested"
                        :style="!isKeyEmpty(props.active?.['Earn']) ? 'display: block;': ''"
                    >
                        <li class="ocn-list-item">
                            <span
                                v-if="props.hasPreMembershipLinksOnly && props.restrictedReferFriend"
                                class="ocn-list-item__no-access ocn-list-link"
                                v-html="props.t.referFriendText"
                            />
                            <span v-else>
                                <a
                                    v-if="props.newReferralProgram && props.showShareAndEarn"
                                    :href="'/' + props.currentUserLocale + '/training/share_and_earn'"
                                    class="ocn-list-link"
                                    :class="{'js-active-item active-item': props.active?.['Earn']?.['/training/share_and_earn']}"
                                    data-ga-category="Navigation Menu"
                                    data-ga-action="Refer_A_Friend"
                                    :data-ga-label="props.gaLabel"
                                    v-html="props.t.referFriendText"
                                />
                                <a
                                    v-if="!props.newReferralProgram"
                                    :href="'/' + props.currentUserLocale + '/training/refer'"
                                    class="ocn-list-link"
                                    :class="{'js-active-item active-item': props.active?.['Earn']?.['/training/refer']}"
                                    data-ga-category="Navigation Menu"
                                    data-ga-action="Refer_A_Friend"
                                    :data-ga-label="props.gaLabel"
                                    v-html="props.t.referFriendText"
                                />
                            </span>
                        </li>
                        <li class="ocn-list-item">
                            <a
                                :href="'/' + props.currentUserLocale + '/member/zwinsider'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Earn']?.['/member/zwinsider']}"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Earnings Support_ZumbaWear Insiders"
                                :data-ga-label="props.gaLabel"
                                v-html="props.t.zumbaRegWearInsiderText"
                            />
                        </li>
                        <li
                            v-if="props.userHasBenefitZinDiscounts"
                            class="ocn-list-item"
                        >
                            <a
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Earn']?.['/member/earn-discount']}"
                                :href="'/' + props.currentUserLocale + '/member/earn-discount'"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Earnings Support_Discounts"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.discountsText }}
                            </a>
                        </li>
                    </ul>
                </li>
                <li
                    v-if="props.canAccessMemberMenu"
                    class="ocn-list-item"
                >
                    <a
                        href="#"
                        class="more-below ocn-list-link"
                        :class="{
                            'js-active-item inactive-item': !isKeyEmpty(props.active?.['Connect']),
                            'pre-selected-active': !isKeyEmpty(props.active?.['Connect'])
                        }"
                    >
                        {{ props.t.communityText }}
                    </a>
                    <ul
                        class="ocn-subnav subnav-nested"
                        :style="!isKeyEmpty(props.active?.['Connect']) ? 'display: block;': ''"
                    >
                        <li
                            v-if="props.userHasBenefitZumbaForumAccess || props.hasPreMembershipLinksOnly"
                            class="ocn-list-item"
                        >
                            <span
                                v-if="props.hasPreMembershipLinksOnly"
                                class="ocn-list-item__no-access ocn-list-link"
                            >
                                {{ props.t.forumText }}
                            </span>
                            <div v-else>
                                <a
                                    class="ocn-list-link"
                                    :href="props.isProduction ? 'https://www.zumba.community/' : 'https://community.staging.zumba.com/'"
                                    data-ga-category="Navigation Menu"
                                    data-ga-action="Community Support_Forum"
                                    :data-ga-label="props.gaLabel"
                                >
                                    {{ props.t.forumText }}
                                </a>
                                <a
                                    class="ocn-list-link"
                                    :class="{'js-active-item active-item': props.active?.['Connect']?.['/zcom']}"
                                    :href="props.isProduction ? 'https://www.zumba.com/zcom' : 'https://www.development.zumba.com/zcom'"
                                    data-ga-category="Navigation Menu"
                                    data-ga-action="Community ZCOM Volunteers"
                                    :data-ga-label="props.gaLabel"
                                >
                                    {{ props.t.zcomVolunteersText }}
                                </a>
                            </div>
                        </li>
                        <Gym
                            :active="props.active"
                            :t="props.t"
                            :current-user-locale="props.currentUserLocale"
                            :ga-label="props.gaLabel"
                            :can-manage-gym-profile="props.canManageGymProfile"
                            :can-manage-linked-gym-instructors="props.canManageLinkedGymInstructors"
                        />
                    </ul>
                </li>
            </ul>
        </li>
        <li
            v-if="!props.isGuestUser"
            class="ocn-list-item"
        >
            <a
                v-if="(props.canManageGymProfile && props.canAccessMemberMenu)"
                class="ocn-list-link"
                :class="{'js-active-item active-item': props.active?.['/profile']}"
                :href="'/' + props.currentUserLocale + props.zinProfileAccountLink"
                data-ga-category="Navigation Menu"
                data-ga-action="My Account"
                :data-ga-label="props.gaLabel"
            >
                {{ props.t.manageMyAccountText }}
            </a>
            <a
                v-else
                class="ocn-list-link"
                :class="{'js-active-item active-item': props.active?.['/profile']}"
                :href="'/' + props.currentUserLocale + '/' + props.zinProfileAccountLink"
                data-ga-category="Navigation Menu"
                data-ga-action="Account"
                :data-ga-label="props.gaLabel"
            >
                {{ props.t.myAccountText }}
            </a>
            <!-- student_dashboard -->
            <a
                v-if="props.canViewStudentDashboard"
                id="hamburger-link-student-dashboard"
                :href="'/' + props.currentUserLocale + '/student_dashboard'"
                class="ocn-list-link"
                :class="{'js-active-item active-item': props.active?.['/student_dashboard']}"
                data-ga-category="Navigation Menu"
                data-ga-action="{{studentDashboardText}}"
            >
                {{ studentDashboardText }}
            </a>
        </li>
        <template v-if="props.userHasDelinquentMembership">
            <li class="ocn-list-item">
                <a
                    class="ocn-list-link"
                    :class="{'js-active-item active-item': props.active?.['/profile']}"
                    :href="'/' + props.currentUserLocale + '/' + props.zinProfileAccountLink"
                    data-ga-category="Navigation Menu"
                    data-ga-action="Tools_My Account"
                    :data-ga-label="props.gaLabel"
                >
                    {{ props.t.myAccountText }}
                </a>
            </li>
            <li class="ocn-list-item">
                <a
                    :href="'/' + props.currentUserLocale + '/training/history'"
                    class="ocn-list-link"
                    :class="{'js-active-item active-item': props.active?.['Tools']?.['/training']}"
                    data-ga-category="Navigation Menu"
                    data-ga-action="Tools_My Trainings"
                    :data-ga-label="props.gaLabel"
                >
                    {{
                        props.dashboardMenuItemsEnabled
                            ? props.t.myTrainingsProgramText
                            : props.t.myTrainingsText
                    }}
                </a>
            </li>
        </template>
    </template>
    <Classes
        v-if="!props.isSixWeekHub"
        :active="props.active"
        :t="props.t"
        :current-user-locale="props.currentUserLocale"
        :ga-label="props.gaLabel"
        :is-six-week-launch-enabled="props.isSixWeekLaunchEnabled"
        :online-class-base-url="props.onlineClassBaseUrl"
        :six-week-splash-page-url="props.sixWeekSplashPageUrl"
        :class-locator-enabled="props.classLocatorEnabled"
        :is-circl-url="props.isCirclUrl"
        :is-zumba-app-menu-launch-enabled="props.isZumbaAppMenuLaunchEnabled"
        :is-job-board-form-enabled="props.isJobBoardFormEnabled"
        :is-gym-post-a-job-enabled="props.isGymPostAJobEnabled"
    />
    <ZumbaApp
        v-if="props.isZumbaAppMenuLaunchEnabled && !props.isZumbaAppNoMenuButtonEnabled"
        :active="props.active"
        :t="props.t"
        :current-user-locale="props.currentUserLocale"
        :ga-label="props.gaLabel"
        :zumba-app-store-url="getAppUrl"
        :virtual-plus-subscription-url="props.virtualPlusSubscriptionUrl"
    />
    <ZumbaAppButton
        v-if="props.isZumbaAppNoMenuButtonEnabled"
        :is-zin="props.isZin"
        :t="props.t"
        :ga-label="props.gaLabel"
    />
    <Instructors
        v-if="!props.isSixWeekHub"
        :active="props.active"
        :t="props.t"
        :user-has-benefit-specialty-training-access="props.userHasBenefitSpecialtyTrainingAccess"
        :is-bot-bai-coronavirus-enabled="props.isBotBaiCoronavirusEnabled"
        :is-maximizeb1-sitenav-trainingtypes-enabled="props.isMaximizeb1SitenavTrainingtypesEnabled"
        :is-online-country-us="props.isOnlineCountryUs"
        :current-user-locale="props.currentUserLocale"
        :ga-label="props.gaLabel"
    >
        <template #sessions>
            <Sessions
                :t="props.t"
                :is-authenticated="props.isAuthenticated"
                :has-pre-membership-links-only="props.hasPreMembershipLinksOnly"
                :active="props.active"
                :current-user-locale="props.currentUserLocale"
                :ga-label="props.gaLabel"
            />
        </template>
    </Instructors>
    <Shop
        v-if="!props.isSixWeekHub"
        :t="props.t"
        :navigation-shop-url="props.navigationShopUrl"
    />
    <About
        :active="props.active"
        :t="props.t"
        :current-user-locale="props.currentUserLocale"
        :ga-label="props.gaLabel"
    />
    <slot />
</template>
<script setup lang="ts">
import { track } from '@ts/Util/analytics';
import { computed } from 'vue';
import OnHold from './OnHold.vue';
import Gym from './Gym.vue';
import Shop from './Shop.vue';
import Classes from './Classes.vue';
import ZumbaApp from './ZumbaApp.vue';
import ZumbaAppButton from './ZumbaAppButton.vue';
import About from './About.vue';
import Instructors from './Instructors.vue';
import Sessions from './Sessions.vue';
import Tag from '@components/Tags/Tag.vue'

const props = defineProps({
    active: { type: Object, default: () => {} },
    t: {
        type: Object,
        default: () => ({
            aboutInstructorTrainingsText: 'Instructor Trainings',
            aboutOurClassesText: 'About our Classes',
            aboutText: 'About',
            aboutZumbaText: 'About Zumba',
            accountOnHoldText: 'Account on Hold',
            accountSummaryText: 'Account Summary',
            afaaGfiForZinText: 'AFAA-GFI For ZIN™',
            becomeAnInstructorText: 'Become an Instructor',
            beginnerVideosText: 'Beginner Videos',
            buySixWeekProgramText: 'Buy 6 Week Program',
            careersText: 'Careers',
            choreographyNotesText: 'Choreography Notes',
            classBuzzAppText: 'ClassBuzz™ App',
            classesText: 'Classes',
            circlNewTagText: 'New',
            communityText: 'Community',
            discountsText: 'Discounts',
            eLearningText: 'E-Learning',
            earningsSupportText: 'Earnings + Support',
            findAClassText: 'Find a Class',
            findATrainingText: 'Find a Training',
            findAVirtualClassText: 'Find a Virtual Class',
            findAnEventText: 'Find an Event',
            findAnInPersonClassText: 'Find An In-Person Class',
            findAnInstructorText: 'Find An Instructor',
            forumText: 'Forum',
            gymAuditionKitText: 'Gym Audition Kit',
            gymsProfileText: 'Gyms Profile',
            hostATrainingText: 'Host a Training',
            instructorAcademiesText: 'Instructor Academies',
            instructorPageText: 'Instructor Page',
            instructorTrainingStatusText: 'Instructor Training Status',
            instructorTrainingsText: 'Instructor Trainings',
            instructorsText: 'Instructors',
            jamSessionsText: 'ZIN&trade; Jam Sessions',
            joinZinText: 'Join ZIN™',
            manageInstructorsText: 'Manage Instructors',
            manageMyAccountText: 'Manage My Account',
            manageMyLocationsText: 'Manage Locations',
            manageZjSessionsText: 'Manage ZJ Sessions',
            marketingMaterialsText: 'Marketing Materials',
            mentorProgramText: 'Mentor Program',
            myAccountText: 'My Account',
            myAnnouncementsText: 'My Announcements',
            myDashboardText: 'My Dashboard',
            myEventsText: 'My Events',
            myTrainingText: 'My Training',
            myTrainingsProgramText: 'My Trainings & Program',
            myTrainingsText: 'My Trainings',
            zinHomeText: 'Zin&trade; Home',
            myZjSessionsText: 'My ZJ Sessions',
            onHoldEndStatusText: 'Your account is currently on hold',
            shopText: 'Shop Clothing',
            sixWeekMenuItemText: '6 Week Program',
            sixWeekProgramText: '6 Week Program',
            sixWeekSplashPageMenuItemText: '6 Week Program',
            stepChallengeText: 'Step Challenge',
            teachingPromotionText: 'Teaching + Promotion',
            tippingText: 'Tipping',
            trainingAttendanceText: 'Training Attendance',
            trainingLocationManagementText: 'Training Location Management',
            trainingMaterialRequestText: 'Training Material Request',
            trainingRegistrationText: 'Training Registration',
            trainingRequestFormText: 'Training Request Form',
            trainingTypeText: 'Training Types',
            trainingsEducationText: 'Trainings + Education',
            updateOnHoldDateText: 'Update on Hold Date',
            updatePaymentText: 'Update Payment',
            userMenuMarketingMaterialsText: 'Marketing Materials',
            viewAllTrainingText: 'View All Training',
            zcomVolunteersText: 'ZCOM Volunteers',
            zesDocsText: 'ZES Docs',
            zesLinksText: 'ZES Links',
            zinNowText: 'ZIN&trade; Now',
            zinPlayAppText: 'ZIN™ Play App',
            zinTradeJamsText: 'ZIN&trade; Jams',
            zumbaBlogText: 'Zumba® Blog',
            zumbaConventionText: 'Zumba Convention',
            zumbaForGymsText: 'Zumba® for Gyms',
            zumbaInstructorLicensingProgramText: 'Zumba Instructor Licensing Program',
            zumbaRegWearInsiderText: 'Zumba&reg; Wear Insider',
            referFriendText: 'Refer A Friend',
            classManagement: 'Class Management',
            newFeature: 'New',
            myClasses: 'My Classes',
            classPacks: 'Class Packs',
            students: 'Students',
            settings: 'Settings',
            localClassesTitleCaseText: 'Local Classes',
            inPersonClassesTitleCaseText: 'In Person',
            zoomClassesTitleCaseText: 'Zoom',
            eventsTitleCaseText: 'Events',
            getTheAppTitleCaseText: 'Get the App',
            virtualClassesTitleCaseText: 'Virtual+ Classes',
            zumbaAppTitleCaseText: 'Zumba App',
            findInstructorsText: 'Find Instructors',
            noomCoachingText: 'Noom Coaching',
            statusCardText: 'ZIN Status Card',
        })
    },
    botPublicZesLinkEnabled: { type: String, default: '' },
    canAccessDcd: { type: String, default: '' },
    canAccessEducationSpecialistDocuments: { type: String, default: '' },
    canAccessEducationSpecialistMenu: { type: String, default: '' },
    canAccessMemberMenu: { type: String, default: '' },
    canAccessSixWeeksProgramProfile: { type: String, default: '' },
    canViewStudentDashboard: { type: Boolean, default: false },
    canConsumerViewDashboard: { type: String, default: '' },
    canGymOwnerViewDashboard: { type: String, default: '' },
    canManageGymProfile: { type: String, default: '' },
    canManageJamSessions: { type: String, default: '' },
    canManageLinkedGymInstructors: { type: String, default: ''},
    canManageTrainingLocations: { type: String, default: '' },
    canRequestTrainingMaterials: { type: String, default: '' },
    canRequestTrainings: { type: String, default: '' },
    currentUserLocale: { type: String, default: '' },
    dashboardMenuItemsEnabled: { type: String, default: '' },
    gaLabel: { type: String, default: '' },
    hasPendingFirstTraining: { type: String, default: '' },
    hasPreMembershipLinksOnly: { type: String, default: '' },
    isAfaaDashboardEnabled: { type: String, default: '' },
    isAuthenticated: { type: String, default: '' },
    isBotBaiCoronavirusEnabled: { type: String, default: ''},
    isClassBuzzSplashEnabled: { type: String, default: '' },
    isCookieCountryUs: { type: String, default: '' },
    isDgfPublicEnabled: { type: String, default: '' },
    isGuestUser: { type: String, default: '' },
    isMaximizeb1SitenavTrainingtypesEnabled: { type: String, default: ''},
    isMembershipCirclInstructorNavigationEnabled: { type: String, default: '' },
    isMembershipCirclInstructorNavigationNewEnabled: { type: String, default: '' },
    isMembershipTypeZumba: { type: String, default: '' },
    isMenuLinksEnabled: { type: String, default: '' },
    isOnHold: { type: String, default: ''},
    isOnlineCountryUs: { type: String, default: ''},
    isPoseSideMenuEnabled: { type: String, default: '' },
    isProduction: { type: String, default: '' },
    isSixWeekLaunchEnabled: { type: String, default: '' },
    isSixWeekHub: { type: Boolean, default: false },
    isSupportedSixWeekLocale: { type: String, default: '' },
    isTippingLandingLinkLeftMenuEnabled: { type: String, default: '' },
    isZinHomeAcademyLinksEnabled: { type: String, default: '' },
    isNoomCertificationEnabled: { type: String, default: '' },
    isBenefitMembershipStatusCardEnabled: { type: String, default: '' },
    manageClassesAction: { type: String, default: '' },
    manageClassesActive: { type: String, default: '' },
    memberProfileEnabled: { type: String, default: '' },
    membershipTypeSlug: { type: String, default: ''},
    navigationShopUrl: { type: String, default: ''},
    onHoldEnd: { type: String, default: ''},
    onlineClassBaseUrl: { type: String, default: ''},
    shouldShowJoinLink: { type: String, default: ''},
    userHasDelinquentMembership: {type: String, default: ''},
    isPrePaymentAccessEnabled: {type: String, default: ''},
    sixWeekSplashPageUrl: { type: String, default: ''},
    userHasBenefitCreateOnlineClass: { type: String, default: '' },
    userHasBenefitInstructorProfile: { type: String, default: '' },
    userHasBenefitManageZinEvents: { type: String, default: '' },
    userHasBenefitManageZjSession: { type: String, default: '' },
    userHasBenefitMemberELearning: { type: String, default: '' },
    userHasBenefitPoseChallengeAccess: { type: String, default: '' },
    userHasBenefitSixWeekProgram: { type: String, default: '' },
    userHasBenefitSpecialtyTrainingAccess: { type: String, default: ''},
    userHasBenefitStrongInstructorAccess: { type: String, default: '' },
    userHasBenefitUseZinMentorProgram: { type: String, default: '' },
    userHasBenefitZinDiscounts: { type: String, default: '' },
    userHasBenefitZinJammerSessions: { type: String, default: '' },
    userHasBenefitZumbaForumAccess: { type: String, default: '' },
    userHasBenefitMemberPortal: {type: String, default: ''},
    userHasNoomCertificationAccess: {type: String, default: ''},
    userHasNoomBenefit: {type: String, default: ''},
    userHasBenefitMembershipStatusCard: { type: String, default: '' },
    zinProfileAccountLink: { type: String, default: '' },
    classManagementEnabled: { type: Boolean, default: false },
    classLocatorEnabled: {type: String, default: ''},
    isCirclUrl: {type: String, default: ''},
    restrictedReferFriend: {type: Boolean, default: false},
    restrictedInstructorTraining: {type: Boolean, default: false},
    isZumbaAppMenuLaunchEnabled: {type: String, default: ''},
    isZumbaAppNoMenuButtonEnabled: {type: Boolean, default: false},
    isZin: {type: Boolean, default: false},
    virtualPlusSubscriptionUrl: {type: String, default: ''},
    appStoreUrl: {type: String, default: ''},
    playStoreUrl: {type: String, default: ''},
    downloadAppUrl: {type: String, default: ''},
    appLandingUrl: {type: String, default: ''},
    newReferralProgram: {type: Boolean, default: false},
    showShareAndEarn: {type: Boolean, default: false},
    isJobBoardFormEnabled: {type: String, default: ''},
    hideJapaneseMenu: {type: Boolean, default: false},
    isGymPostAJobEnabled: {type: Boolean, default: false}
})

const showNoom = computed(() => props.isNoomCertificationEnabled && props.userHasNoomCertificationAccess && !props.hideJapaneseMenu)

const openTools = computed((): boolean => {
    const currentUrl = window.location.pathname
    return props.classManagementEnabled && (
        currentUrl.startsWith('/' + props.currentUserLocale + '/dashboard')
    )
})

const getAppUrl = computed(() => {
    let url = props.downloadAppUrl
    const isIos = navigator.userAgent.match(/iP(ad|hone|od)/i) !== null;
    const isAndroid = navigator.userAgent.match(/Android/i) !== null;

    if (isAndroid) {
        url = props.playStoreUrl;
    }
    if (isIos) {
        url = props.appStoreUrl;
    }
    return url;
});

const openClassManagement = computed((): boolean => {
    const currentUrl = window.location.pathname
    return props.classManagementEnabled && (
        currentUrl.startsWith('/' + props.currentUserLocale + '/member/class_management') ||
        currentUrl.startsWith('/' + props.currentUserLocale + '/member/instructor_page/') ||
        currentUrl.startsWith('/' + props.currentUserLocale + '/member/packages')
    )
})

const openNestedSubmenu = computed((): boolean => {
    return props.active?.['Teach'] || props.active?.['Learn'] || props.active?.['Earn'] || props.active?.['Connect']
})
const isKeyEmpty = (key) => Object.keys(key ?? {}).length == 0

const studentDashboardText = computed(() => props.userHasBenefitMemberPortal ? props.t.activityAsAStudentText : props.t.activityText)

</script>

<style lang="scss" scoped>
.new-feature-item {
    flex: 1;
}

.new-feature-tag-wrap  {
    flex: .6;
}
.new-feature-tag {
    text-transform: capitalize;
    margin-left: 1rem;
}
:deep(.new-feature-tag.tag) {
    display: inline;
}
:deep(.subnav-nested) {
    a, li {
        background: var(--zumba-gray-600);
    }
}
</style>
